body {
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-flex.align-items-center.justify-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


/* 
/* styles.css from mums site * /
.announcement {
  background: #2e91fc;
  color: #fff;
}
a.announcement:hover {
  color: #fff;
}
/* end styles.css from mums site * /

/* App.css */
#root {
  text-align: center;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.tab-menu-container {
  display: flex;
  align-items: center;
}

.tab-menu-image {
  margin-right: 10px;
}

.float-left {
  float: left;
}

.logo {
  max-height: 70px;
  position: relative;
  top: 50%;
  max-width: 100%;
  max-height: 40px;
  margin: 0;
  transform: translateY(-50%);
}

.background-image {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #fff; }

.background-image--sink {
  background-image: url(./images/sink.jpg);
}

/* styles.css */
[data-amplify-theme] {
  /* background for the whole tab bar */
  --amplify-components-tabs-background-color: var(
    --amplify-colors-background-secondary
  );
  /* background for each tab */
  --amplify-components-tabs-item-background-color: var(
    --amplify-colors-background-primary
  );
}

.orange-button {
  background-color: #E67E22 !important;
  border:none !important;
  padding: 1em !important;
  opacity: 0.8 !important;
}

.blue-button {
  background-color: #2e91fc !important;
}

.clear-button {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
}

.button-text{
  font-size: 16px !important;
}

.orange-card {
  background-color: #E67E22 !important;
  color: #fff; /* sets the text color to white */
  padding: 1em !important;
}

.card {
  border-radius: 0 !important;
}


.orange-card .card {
  background-color: transparent;
}

.blue-card {
  background-color: #2e91fc !important;
  color: #fff; /* sets the text color to white */
  padding: 0.5em !important;
}

.blue-card .card {
  background-color: transparent;
}

.grey-card {
  background-color: #ecf0f1 !important;
  /* margin-bottom: 1em; */
  padding: 1em !important;
}

.grey-card .card {
  background-color: transparent;  margin-bottom: 1em;
}

.light-grey-card {
  background-color:#f9f9f9 !important;
  /* margin-bottom: 1em; */
  padding: 1em !important;
}

.light-grey-card .card {
  background-color: transparent;  margin-bottom: 1em;
}

.clear-card {
  background-color: transparent !important;
  padding: 1em !important;
  border: none;
  box-shadow: none;
}

.clear-card .card {
  background-color: transparent;
}

.borderless {
  border: none !important;
}

.transparent {
  background-color: transparent !important;
}

.row {
  margin-left: -15px;
  margin-right: -15px
}

.center-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.center-card .card-text {
  width: 70%;
  text-align: center;
  /* margin-bottom: 1em; */
  font-size: 1.5em;
}

.card-text{
  font-size: 1.5em;
  margin-bottom: 0;
}

.card-space{
  margin-bottom: 1.5em !important;
}

.ac-card-title{
  color: #E67E22 !important;
  font-size: 2.5em !important;
}

.title-text{
  font-size: 1.5em !important;
  text-align: center;
}

.large-text{
  font-size: 1.5em !important;
}

.orange-font{
  color: #E67E22 !important;
}

.blue-font{
  color: #2e91fc !important;
}

.bold{
  font-weight: bold !important;
}

.text-left{
  text-align: left !important;
}

.my-list {
  font-size: 0.8em !important;
  margin-bottom: 16px !important;
}

.no-border {
  border: none;
}

.carousel-item {
  margin-bottom: 2em;
}

.testimonial-text{
  font-size: 24px;
}

.testimonial-name{
  font-size: 24px;
  font-weight: bold;
}

.testimonial-address{
  font-size: 24px;
  font-style: italic;
}

/* set the background color of the Accordion */
.accordion {
  background-color: inherit;
}

/* set the border color of the Accordion */
.accordion-button {
  border-color: inherit;
}

/* set the text color and background color of the Accordion header when it is expanded */
.accordion-button[aria-expanded="true"] {
  color: #E67E22;
  background-color: inherit;
}

/* set the text color and background color of the Accordion header when it is collapsed */
.accordion-button[aria-expanded="false"] {
  color: inherit;
  background-color: inherit;
}

/* set the background color of the Accordion body */
.accordion-collapse {
  background-color: inherit;
}


/* Style for the accordion header */
.accordion-header {
  background-color: inherit;
  border: inherit;
  color: #E67E22;
  font-size: inherit;
  font-weight: inherit;
}

/* Style for the accordion button */
.accordion-button {
  background-color: inherit;
  border: inherit;
  color: #E67E22;
  font-size: inherit;
  font-weight: inherit;
}

.accordion-item.active {
  background-color: #fff;
  border: none;
}

button.accordion-button:not(.collapsed),
button.accordion-button {
  color: #E67E22 !important;
  font-size: inherit;
  font-weight: inherit;
}

.accordion-header,
.accordion-button[aria-expanded="true"] {
  background-color: transparent !important;
  color: inherit !important;
  border: none !important;
  font-size: inherit !important;
  font-weight: bold !important;
}

.accordion-item:focus-within,
.accordion-button:focus {
  background-color: white !important;
  box-shadow: none !important;
}


.amplify-tabs-item{
  color: #E67E22 !important;
}

.margin-top,
.margin-bottom {
  margin-top: 1em;
}

.tab-content {
  width: 100%;
}

:root {
  --amplify-components-tabs-item-active-border-color: #E67E22 !important;
}

.nav-link {
  color: #E67E22 !important;
  margin: 0 15px;
}

.font-normal{
  font-size: 14px !important;
}

.font-medium{
  font-size: 20px !important;
}

.font-black{
  color: #000 !important;
  font-weight: 600 !important;
  
}

.tabs {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.tabItem {
  opacity: 1;
  pointer-events: none;
  color: inherit;
  background-color: inherit;
  border-color: inherit;
  border-bottom: none;
  box-shadow: none;
}

.ac-list-container {
  margin-bottom: 20px;
}

.ac-list {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
}

.ac-list li {
  margin-bottom: 10px;
}

.ac-list-note {
  margin-top: 20px;
  font-style: italic;
}

.panel__body.fluid-video {
  text-align: left;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.image-container {
  flex: 1;
  margin-right: 16px;
}

.text-container {
  flex: 1;
  background-color: #fff;
  padding: 16px;
}

.text-container h2 {
  margin-top: 0;
}

.line {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}


@media screen and (max-width: 768px) {
  .orange-button {
    font-size: 1.2rem !important;
  }

  .grey-card,
  .light-grey-card {
    width: 100%;
  }

  .card-text,
  .testimonial-text,
  .testimonial-address,
  .testimonial-name,
  .button-text,
  .font-normal, 
  .font-medium {
    font-size: 16px !important;
  }

  .large-text{
    font-size: 1em;
  }

  .ac-card-title{
    font-size: 1.5em !important;
  }

  .center-card .card-text {
    width: 100%;
    font-size: 1em;
  }

  .container {
    flex-direction: column;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
